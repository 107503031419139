.notification-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  color: var(--text-black);
  font-size: 14px;
  font-family: var(--INTER);
  line-height: 24px;
  cursor: pointer;
}

@media (max-width: 1560px) {
  .notification-icon {
    width: 50px;
    height: 50px;
  }
}

.notification-icon {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--text-color);
}

.notification-icon img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.notification-item:not(:last-child) {
  border-bottom: 1px solid #cfd9e8;
}

.notification-item p {
  margin: 0;
}

.notification-item__number {
  color: var(--accent);
  font-weight: 500;
  text-decoration: underline;
}

.notification-item__number:hover {
  color: var(--hover);
}

.notification-item__text {
  color: #000;
  font-weight: 500;
}

.notification-item__date {
  color: #bdc6d1;
  font-size: 14px;
}

.notification-item-name {
  display: inline;
  color: var(--text-black);
  font-size: 14px;
  font-family: var(--INTER);
  font-weight: 500;
  line-height: 24px;
}

.notification-item-date,
.notification-item-status {
  display: inline;
  display: flex;
  color: var(--text-black);
  font-size: 14px;
  font-family: var(--INTER);
  line-height: 24px;
}
.notification-item-detail {
  display: inline;
  display: flex;
  color: var(--text-black);
  font-size: 12px;
  font-family: var(--INTER);
  line-height: 24px;
}
.notification-item-date {
  font-weight: 500;
  font-size: 12px;
}

.notification-item-link {
  color: #4339f2;
  font-size: 12px !important;
  line-height: 18px !important;
  text-transform: none;
}

.paper-wrapper {
  width: 440px;
  border-radius: 24px !important;
}

.mark-laber {
  color: #4339f2;
  font-size: 12px;
  text-transform: none;
  position: relative;
}
.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #e2e8f0;
}

.notification-wrapper {
  padding: 40px 40px 0px 40px;
}

.notification-title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}

.notification-title {
  color: var(--header);
  text-align: center;
  font-size: 24px;
  line-height: normal;
  margin: 0;
  margin-bottom: 64px;
}

.notification-footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  border-top: 1px solid #cfd9e8;
  font-family: var(--INTER);
}

.info {
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  color: var(--accent);
  font-size: 16px;
  cursor: pointer;
}

.info:hover {
  color: var(--hover);
}
