@media (max-width: 1560px) {
    .news-widget {
        padding: 20px;
    }

    .news-widget-title {
        font-size: 18px;
    }

    .news-widget-date {
        font-size: 14px;
    }

    .news-widget-text {
        font-size: 14px;
    }
}