@media (max-width: 1560px) {
    .sidebar .sidebar-list-row-content-text {
        font-size: 18px;
    }

    .sidebar-list-row-content-icon {
        width: 26px;
    }

    .sidebar-opened {
        min-width: var(--widthFullSideBarSmallDescktop);
    }
}