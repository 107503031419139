@media (max-width: 1560px) {
    .tasks-count-widget {
        padding: 20px;
        font-size: 14px;
    }

    .tasks-count-widget-title {
        font-size: 18px;
    }

    .tasks-count-widget-count {
        font-size: 26px;
    }

    .tasks-count-widget-header a {
        font-size: 14px;
    }

    .tasks-count-widget-item {
        margin: 0px 5px;
        width: fit-content;
    }

    .tasks-count-widget-item p {
        text-align: center;
    }

    .tasks-count-widget-title-container {
        gap: 15px;
    }

    .tasks-count-widget-header {
        gap: 10px;
    }
}