
.grid-type-container {
  display: flex;
  background-color: var(--white);
  padding: 8px;
  border-radius: 100px; 
}

.grid_type_tab {
  display: flex;
  padding: 18px 0;
  width: 175px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.grid_type_tab_active {
  background-color: var(--bgListItem);
  border-radius: 100px;
}

@media (max-width: 1725px) {
  .grid_type_tab {
    padding: 18px;
    width: fit-content;
    gap: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
