.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 36px;
}
.details-header .details-header-title {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--modalContext);
  line-height: 28px;
  color: var(--black);
  padding-right: 36px;
}
.details .MuiStack-root .css-1xhypcz-MuiStack-root {
  padding: 0;
}
.details .details-body {
  padding: 0;
  overflow-y: hidden;
}
.details-body .MuiTabs-root {
  padding-left: 19px;
}
.details-body .MuiTabs-root .Mui-selected {
  color: var(--black);
}
.details-body .label {
  text-transform: initial;
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
}
.details-body .requisites-btn-field {
  display: flex;
  justify-content: flex-end;
  padding: 32px;
  padding-bottom: 0;
}
.details-body .requisites-content {
  padding: 0 32px;
}
.details-body .requisites-content-block {
  padding-bottom: 24px;
}
.details-body .requisites-content-block-title {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 20px;
  color: var(--additionalGrey);
  border-bottom: 1px solid var(--bgTableHeader);
  padding-bottom: 4px;
}
.details-body .requisites-content-block-textField {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.details-body .requisites-content-block-textField-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 20px;
  color: var(--black);
  padding-top: 20px;
}
.details-body .requisites-content-block-textField-text {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 20px;
  color: var(--black);
  padding-top: 20px;
}
.details-body .objects {
  padding: 24px 36px;
}
.details-body .objects-btn-field {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding-bottom: 24px;
}
.details-body .objects-item {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
}

.organization-object-table {

}

.organization-object-table__header {
  display: grid;
  grid-template-columns: 250px 250px 300px 250px;
  border: 1px solid #cfd9e8;
  border-radius: 40px;
}

.organization-object-table__header-item {
  padding: 13px;
  text-align: center;
}

.organization-object-table__header-item:not(:last-child)  {
  border-right: 1px solid #cfd9e8;
}

.organization-object-table__row {
  display: grid;
  grid-template-columns: 250px 250px 300px 250px;
  text-align: center;
}

.organization-object-table__row:not(:last-child) {
  border-bottom: 1px solid #cfd9e8;
}

.organization-object-table__row-item {
  padding: 15px;
}

.organization-object-table__row-item svg {
  transition: .3s ease;
}

.organization-object-table__row-item svg:hover {
  color: var(--accent);
  cursor: pointer;
  transition: .3s ease;
}

