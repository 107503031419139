@media (max-width: 1560px) {
  body .app_header {
    width: calc(100% - var(--widthFullSideBarSmallDescktop));
  }

  body .app_header.closed-side-bar {
    width: calc(100% - var(--widthShortSideBar));
  }

  .header-navbar-text {
    gap: 40px;
  }
}

@media (max-width: 1280px) {
    .header-navbar-text {
        gap: 20px;
      }
}
