.chat-container {
  position: absolute;
  left: 355px;
  bottom: -24px;
  width: 500px;
  height: 700px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  border-radius: 0 24px 24px 0;
  background-color: white;
}

.chat-container.closed-sidebar {
  left: 90px;
}

@media (max-width: 1560px) {
  .chat-container {
    left: 306px;
  }
}

.header {
  position: relative;
  height: 40px;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #c3cbd2;
  border-radius: 0 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header p {
  font-size: 24px;
  color: #6c6e70;
}

.chat-hello {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #030c23;
  font-size: 24px;
  font-weight: 500;
}

.chat-hello span {
  color: #0291f7;
}

.chat-hello-img {
  margin-bottom: 16px;
  width: 100px;
  height: 100px;
}

.chat-hello-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.chat-hello p {
  margin: 0;
  margin-bottom: 24px;
}

.chat-hello p:last-child {
  font-weight: 400;
  font-size: 20px;
}

.chat-close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: 0.3s ease;
}

.chat-close:hover svg {
  transition: 0.3s ease;
  color: var(--accent);
}

.chat-close svg {
  width: 20px;
  height: 20px;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: scroll-behavior 0.3s ease-out;
}

.bot-equipments {
  display: flex;
  gap: 18px;
  width: 80%;
  margin: 5px 0 10px 40px;
}

.bot-equipments > img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.user-message {
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 24px;
  align-self: flex-end;
  background-color: #e0f2ff;
  color: #030c23;
  max-width: 80%;
}

.bot-message {
  display: flex;
  gap: 12px;
  align-self: flex-start;
  background-color: inherit;
  color: #030c23;
  max-width: 80%;
  font-weight: 500;
}

.bot-message img {
  width: 30px;
  height: 30px;
  max-height: 30px;
  border-radius: 50%
}

.bot-message p {
  margin: 0;
}

.input-container {
  padding: 0 24px 24px 24px;
  background-color: #e2e3e8;
  border-radius: 0 0 24px 0;
  background-color: #fff;
}

.message-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
}

.send-button {
  padding: 9px 13px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 100%;
  width: fit-content;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

.send-button:disabled {
  background-color: #e2e3e8;
  cursor: auto;
}

.send-button:disabled svg {
  color: #b9baba;
}

.message-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.textarea {
  border: none;
  background-color: inherit;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--INTER);
}

textarea::-webkit-resizer {
  display: none;
}

.textarea:focus-visible {
  outline: none;
}

.textarea::placeholder {
  font-family: var(--INTER);
}

.textarea-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  height: 140px;
  background-color: #f4f6f6;
  border-radius: 16px;
  border: 1px solid #e2e3e8;
}

.textarea-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.textarea-ai-container {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #0291f7;
}

.textarea-ai-img {
  width: 16px;
  height: 16px;
}

.textarea-ai-img img {
  width: 100%;
  object-fit: contain;
}

.typing-indicator {
  display: flex;
  gap: 2px;
  color: #b9baba;
  font-weight: 500;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.dots {
  display: flex;
  margin-top: 5px;
}

.dot {
  height: fit-content;
}

.dots .dot {
  animation: blink 1s infinite;
  opacity: 0;
}

.dots div:nth-child(1) { animation-delay: 0s; }
.dots div:nth-child(2) { animation-delay: 0.2s; }
.dots div:nth-child(3) { animation-delay: 0.4s; }

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}
